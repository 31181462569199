import axios from "axios";

const axiosClientFromWeb = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

// Centralized error handling function (example)
const handleError = (error, callback) => {
  // Handle error here or pass it to callback

  console.error("API Error:", error);
  //   callback(error.response?.status, error.response?.data);
};

export async function getNewsCategory(params, callback) {
  try {
    const res = await axiosClientFromWeb.get("/news-category", params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function getNews(params, callback) {
  try {
    const res = await axiosClientFromWeb.get("/news", params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function deleteNews(id, callback) {
  try {
    const res = await axiosClientFromWeb.delete(`/news/${id}`, {});
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function createNews(params, callback) {
  try {
    const res = await axiosClientFromWeb.post("/news", params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function editNews(id, params, callback) {
  try {
    const res = await axiosClientFromWeb.put(`/news/${id}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function getNewsById(id, callback) {
  try {
    const res = await axiosClientFromWeb.get(`/news/${id}`, {});
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function updateStatus(id, params, callback) {
  try {
    const res = await axiosClientFromWeb.patch(`/news/${id}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function updatePriorlitize(params, callback) {
  try {
    const res = await axiosClientFromWeb.patch(`/news`, params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function createManuals(params, callback) {
  try {
    const res = await axiosClientFromWeb.post(`/manual`, params);
    callback(200, res);
  } catch (error) {
    window.alert("สร้างไม่สำเร็จเนื่องจากมีข้อมูลหน้านี้ในระบบแล้ว");
    handleError(error);
  }
}

export async function getManual(params, callback) {
  try {
    const res = await axiosClientFromWeb.get("/manual", { params });
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function getManualByID(id, callback) {
  try {
    const res = await axiosClientFromWeb.get(`/manual/${id}`, {});
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function updateManuals(id, params, callback) {
  try {
    const res = await axiosClientFromWeb.put(`/manual/${id}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function deleteManuals(id, callback) {
  try {
    const res = await axiosClientFromWeb.delete(`/manual/${id}`, {});
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function updateManualsStatus(id, params, callback) {
  try {
    const res = await axiosClientFromWeb.patch(`/manual/${id}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function menuPermission({}, callback) {
  try {
    const res = await axiosClientFromWeb.get(`/m-permission`, {});
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export async function uploadImg(formData, callback, onProgress) {
  try {
    const res = await axiosClientFromWeb.post(`/news/upload`, formData, {
      onUploadProgress: (progressEvent) => {
        if (onProgress) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(percentCompleted);
        }
      },
    });
    callback(200, res);
  } catch (error) {
    handleError(error);
  }
}

export default axiosClientFromWeb;
