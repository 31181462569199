import React, { useEffect, useState } from "react";
import FormNews from "./FormNews";
import { Description } from "./mockup";
import { editNews, getNewsById } from "../fetchApi/apiClient";
import { Spin } from "antd";

const NewsEdit = (props) => {
  const [initialValues, setInitialValues] = useState(null); // ใช้ state เพื่อเก็บค่า initialValues
  const [uploadFile, setUploadFile] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getNewsById(props.match.params.id, (status, res) => {
      const data = {
        titleName: res.data.data.topic,
        description: !!res.data.data.description
          ? res.data.data.description
          : undefined,
        newsCatagory: res.data.data.news_category_id.toString(),
        content: res.data.data.detail,
        isPublish: res.data.data.is_publish,
      };

      setInitialValues(data); // อัปเดตค่า state
      if (!!res.data.data.cover) {
        setUploadFile([
          {
            uid: "0",
            name: "",
            url: res.data.data.cover,
            thumbUrl: res.data.data.cover,
          },
        ]);
      } else {
        setUploadFile([]);
      }
      setLoading(false);
    });
  }, [props.match.params.id]);

  if (!initialValues) {
    return (
      <Spin spinning={true}>
        <FormNews
          initialValues={{
            titleName: "",
            description: "",
            cover: "",
            newsCatagory: "1",
            content: JSON.stringify([
              {
                type: "paragaph",
                children: [{ text: "" }],
              },
            ]),
            isPublish: false,
          }} // ใช้ค่าจาก state
          readOnly={false}
          onSubmit={(val) => {}}
        />
      </Spin>
    ); // แสดงข้อความระหว่างรอข้อมูลจาก API
  }

  return (
    <Spin spinning={loading}>
      <FormNews
        initialValues={initialValues} // ใช้ค่าจาก state
        readOnly={false}
        onSubmit={(val) => {
          setLoading(true);
          editNews(
            props.match.params.id,
            {
              topic: val.titleName,
              description: val.description,
              cover: val.cover[0]["url"],
              news_category_id: val.newsCatagory,
              detail: val.content,
              is_publish: val.isPublish,
            },
            (status, res) => {
              setLoading(false);
              window.alert("แก้ไขสำเร็จ");
              window.location.replace("/news-manuals");
            }
          );
        }}
        imgList={uploadFile}
      />
    </Spin>
  );
};

export default NewsEdit;
