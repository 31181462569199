import { Divider, List } from "antd";
import React, { useState } from "react";
import { Icons } from "../Asset/Icon";

const TabLists = ({ list, handleSelectType, isActiveId }) => {
  return (
    <>
      {list.length > 0 && (
        <>
          <List
            itemLayout="horizontal"
            dataSource={list}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => {
                  handleSelectType(item);
                }}
                style={{
                  // padding: "10px 0px",
                  // cursor: "pointer",
                  ...(item.id == isActiveId && { backgroundColor: "#DFE3E8" }),
                }}
                className="ListItemMetaLandingPage"
              >
                <List.Item.Meta
                  // avatar={}
                  title={
                    <label style={{ cursor: "pointer", fontWeight: 700 }}>
                      <label
                        style={{
                          marginLeft: 10,
                          marginRight: 8,
                          marginTop: 5,
                          cursor: "pointer",
                        }}
                      >
                        {Icons[item.news_category_id].icon()}
                      </label>
                      {item.topic}
                    </label>
                  }
                />
              </List.Item>
            )}
          />
          <Divider style={{ margin: 0 }} />
        </>
      )}
    </>
  );
};

export default TabLists;
