import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadSecBySecReport(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const {
    data: {
      list_vehicle_id,
      date_start,
      date_end,
      type_file,
      event_type,
      attachment_type,
      speed_over_60,
    },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/secbysec`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: `${COMPANY_ID}`,
        type_file,
        attachment_type,
        speed_over_60,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadSecBySecReport.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    console.log("error", err);
    yield put(actions.loadSecBySecReport.failure());
  }
}

export default function* watchSecBySec() {
  yield all([
    takeEvery(actions.LOAD_SECBYSEC_REPORT.REQUEST, loadSecBySecReport),
  ]);
}
export { loadSecBySecReport };
