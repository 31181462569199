import React from "react";
import { GeneralStyledContent } from "../../../../styled/common-styled";
import { Tabs, Row, Col } from "antd";
import LangContext from "modules/shared/context/langContext";
import Breadcrumb from "../../../shared/components/Breadcrumb";
import { connect } from "react-redux";
import ManualsMain from "./ManualsMain";
import NewsMain from "./NewsMain";
import AuthorizeComponent from "../../../auth/components/AuthorizeComponent";

const { TabPane } = Tabs;

const Index = (props) => {
  const handleTabs = (key) => {};
  return (
    // <AuthorizeComponent matching_name="newsManual">
    <LangContext.Consumer>
      {(i18n) => (
        <GeneralStyledContent>
          <Row>
            <Col span={24}>
              <Breadcrumb
                match={props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>

            <Col span={24}>
              <Tabs defaultActiveKey="1" onChange={handleTabs}>
                <TabPane tab={i18n.newsManuals.news} key="1">
                  <NewsMain />
                </TabPane>
                <TabPane tab={i18n.newsManuals.manuals} key="2">
                  <ManualsMain />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </GeneralStyledContent>
      )}
    </LangContext.Consumer>
    // </AuthorizeComponent>
  );
};

export default connect(
  ({ auth }) => ({
    auth,
  }),
  {}
)(Index);
