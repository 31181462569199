import React, { Component, Fragment } from "react";
import { Divider, Icon, Table, Tag, Tooltip } from "antd";
import ReactDragListView from "react-drag-listview";
import { helper_convertDateformat } from "../../../../lib/helper";
import LangContext, { i18n } from "modules/shared/context/langContext";
import { Resizable } from "react-resizable";
import { debounce } from "debounce";

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
export class CustomTable extends Component {
  state = {
    columnsLastestAdjust: null,
    columns: [],
    dragProps: {
      onDragEnd(fromIndex, toIndex) {
        return false;
      },
      nodeSelector: "th",
    },
    showSaveBtn: false,
    dataTable: [],
  };

  initDynamicColumns() {
    let self = this;
    const columns = this.getCol();

    this.setState(
      {
        columnsLastestAdjust: new Date().getTime(),
        columns,
        dragProps: {
          onDragEnd(fromIndex, toIndex) {
            const columns = self.state.columns;
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            self.setState({
              columnsLastestAdjust: new Date().getTime(),
              columns,
            });
            let data = columns
              .map((ele) => ele.dataIndex)
              .filter((ele) =>
                ele != "key" && ele != "button" ? true : false
              );

            fetch(
              `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/updateuserconfigtrackingcolumn`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${self.props.auth.accessToken}`,
                },
                body: JSON.stringify({
                  user_id: self.props.profile.id,
                  columns: JSON.stringify(data),
                }),
              }
            )
              .then((response) => response.json())
              .then((res) => {
                self.props.changeConfigTrackingColumns(
                  self.props.profile,
                  data
                );
              });
          },
          nodeSelector: "th",
        },
      },
      () => this.props.getDragColumn(this.state.columns)
    );
  }

  componentDidMount() {
    this.initDynamicColumns();
  }

  checkWidth = () => {
    if (typeof this.props.auth.profile.config != "undefined") {
      if (typeof this.props.auth.profile.config.menu != "undefined") {
        if (
          typeof this.props.auth.profile.config.menu.tracking != "undefined"
        ) {
          if (
            typeof this.props.auth.profile.config.menu.tracking
              .columns_car_plate_no != "undefined"
          ) {
            if (
              this.props.auth.profile.config.menu.tracking.columns_car_plate_no
                .length != 0
            ) {
              return true;
            }
          }
          return false;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  checkWidth = () => {
    if (typeof this.props.auth.profile.config != "undefined") {
      if (typeof this.props.auth.profile.config.menu != "undefined") {
        if (
          typeof this.props.auth.profile.config.menu.tracking != "undefined"
        ) {
          if (
            typeof this.props.auth.profile.config.menu.tracking
              .columns_car_plate_no != "undefined" &&
            this.props.auth.profile.config.menu.tracking.columns_car_plate_no !=
              null
          ) {
            if (
              this.props.auth.profile.config.menu.tracking.columns_car_plate_no
                .length != 0
            ) {
              return true;
            }
          }
          return false;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  getWidth = (dataIndex, width) => {
    let data = this.props.auth.profile.config.menu.tracking.columns_car_plate_no[0].Column.find(
      (x) => x.dataIndex === dataIndex
    );
    if (typeof data != "undefined") {
      return data.width;
    }
    return width;
  };

  getKeyTurningStatus = (engineVolStatus) => {
    switch (engineVolStatus) {
      case 1:
        return "ติดเครื่อง";
      case 2:
        return "ดับเครื่อง";
      case 3:
        return "ดับเครื่องบิดกุญแจ";
      default:
        return "-";
    }
  };

  getCol = () => {
    let self = this;
    let config = this.props.profile.config;
    let {
      menu: {
        tracking: { columns },
      },
    } = config;

    let indexCol = {
      title: "#",
      width: 40,
      ellipsis: true,
      className: "column-text-center",
      dataIndex: "key",
      render(text, record, index) {
        return {
          children: <div>{index + 1}</div>,
        };
      },
    };
    let actionCol = {
      title: "",
      dataIndex: "button",
      key: "button",
      className: "column-money",
      width: 15,
      render: (text, record, index) => {
        return (
          <div>
            <Tooltip
              placement="left"
              title={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.detail}
                </LangContext.Consumer>
              }
              arrowPointAtCenter
            >
              <a
                href="#"
                onClick={() => {
                  this.props.openInfoPanel(
                    this.props.trackingList,
                    record.vehicle_id
                  );
                }}
                style={{ padding: "0px 15px 0px 5px" }}
              >
                <Icon type="double-right" />
              </a>
            </Tooltip>
          </div>
        );
      },
    };
    let allCol = [
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.p.plateNo} placement="left">
                {i18n.p.plateNo}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "plate_no",
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("plate_no", 190, this.props.auth.profile.config)
          : 190,
        className: "column-text-center",
        ellipsis: true,
        sorter: (a, b) => ("" + a.plate_no).localeCompare(b.plate_no),
        render: (text, record, index) => {
          return text;
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.k.kmH} placement="left">
                {i18n.s.speed}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "velocity",
        ellipsis: true,
        className: "column-text-center",
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("velocity", 80, this.props.auth.profile.config)
          : 80,
        sorter: (a, b) => a.velocity - b.velocity,
        render(text, record, index) {
          return {
            children: (
              <div>
                {record.latest_pos_update_at != "now" ? (
                  <span>{text}</span>
                ) : parseInt(text) >= 90 ? (
                  <span style={{ color: "red", fontWeight: 700 }}>{text}</span>
                ) : parseInt(text) > 0 ? (
                  <span>{text} </span>
                ) : (
                  <span>{text}</span>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.m.meter} placement="left">
                {i18n.m.meter}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "meter_fare",
        ellipsis: true,
        className: "column-text-center",
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("meter_fare", 120, this.props.auth.profile.config)
          : 120,
        render(text, record, index) {
          let component = record.meter_diff_percent;
          if (record.meter_diff_percent !== "-") {
            let txt = `${record.meter_fare}/${record.gps_fare}`;
            let percent = parseFloat(record.meter_diff_percent);
            if (percent > 10) {
              component = (
                <div>
                  <p
                    style={{
                      color: "red",
                      marginBottom: "3px",
                      marginTop: "8px",
                    }}
                  >
                    {txt}
                  </p>
                  <p
                    style={{
                      color: "red",
                      marginBottom: "0px",
                      fontSize: "8px",
                      textAlign: "end",
                    }}
                  >
                    {record.meter_diff_percent}%
                  </p>
                </div>
              );
            } else {
              component = (
                <span>
                  <p
                    style={{
                      color: "green",
                      marginBottom: "3px",
                      marginTop: "8px",
                    }}
                  >
                    {txt}
                  </p>
                  <p
                    style={{
                      color: "green",
                      marginBottom: "0px",
                      fontSize: "8px",
                      textAlign: "end",
                    }}
                  >
                    {record.meter_diff_percent}%
                  </p>
                </span>
              );
            }
          }
          return {
            children: component,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.v.vehicleStatus} placement="left">
                {i18n.v.vehicle}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_status",
        className: "column-text-center",
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("vehicle_status", 100, this.props.auth.profile.config)
          : 100,
        ellipsis: true,
        sorter: (a, b) => a.vehicle_status.length - b.vehicle_status.length,
        render(text, record, index) {
          let color = "";
          let tooltipMessage = "";
          let source = "";
          if (record.vehicle_status == "running") {
            color = "#00BF06";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.r.running}
              </LangContext.Consumer>
            );
            source = "/img/Group 16602.png";
          } else if (record.vehicle_status == "stop-with-engine-on") {
            color = "#ffd619";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.s.stopWithEngineOn}
              </LangContext.Consumer>
            );
            source = "/img/Group 16600.png";
          } else if (record.vehicle_status == "stop-with-engine-off") {
            color = "#c91c1c";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.e.engineOff}
              </LangContext.Consumer>
            );
            source = "/img/Group 16601.png";
          } else if (record.vehicle_status == "connection-error") {
            color = "#848484";
            tooltipMessage = (
              <LangContext.Consumer>
                {(i18n) => i18n.l.lostConnectio}
              </LangContext.Consumer>
            );
            source = "/img/car gray.png";
          }
          return {
            children: (
              <div style={{ position: "relative", height: 30 }}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    width: "100%",
                  }}
                >
                  <Tooltip title={tooltipMessage} placement="left">
                    <img
                      src={source}
                      alt=""
                      style={{ height: "20px", marginTop: -15 }}
                    />
                  </Tooltip>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "-3px",
                  }}
                >
                  <label style={{ fontSize: 8 }}>
                    {!!record.pw_supply &&
                    Array.isArray(record.pw_supply["dc_vol"]) &&
                    typeof record.pw_supply["dc_vol"][1] !== "undefined" ? (
                      record.pw_supply["dc_vol"][1] == -1 ||
                      record.pw_supply["dc_vol"][1] == 9.999 ? (
                        "- "
                      ) : (
                        <label>
                          {(
                            parseInt(
                              record.pw_supply["dc_vol"][1] * Math.pow(10, 2)
                            ) / Math.pow(10, 2)
                          ).toFixed(2)}{" "}
                          V
                        </label>
                      )
                    ) : (
                      "- "
                    )}
                  </label>
                </div>
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.s.slidingCard}
          </LangContext.Consumer>
        ),
        dataIndex: "driver_license_swiping_status",
        className: "column-text-center",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "driver_license_swiping_status",
              100,
              this.props.auth.profile.config
            )
          : 100,
        render(text, record, index) {
          return {
            children: (
              <div style={{ margin: "-4px 0px 0px 0px" }}>
                {record.gps_active_at != "now" ? (
                  <Tooltip
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.l.lostConnectio}
                      </LangContext.Consumer>
                    }
                  >
                    <img
                      src="/img/Group red.png"
                      alt=""
                      style={{ height: "20px" }}
                    />
                  </Tooltip>
                ) : record.engine == 0 ? (
                  text.status ? (
                    <img
                      src="/img/Group green.png"
                      alt=""
                      style={{ height: "20px" }}
                    />
                  ) : text.code == 500 ? (
                    <Tooltip
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.d.doNotSwipeCardsOrDamageCards}
                        </LangContext.Consumer>
                      }
                    >
                      <img
                        src="/img/Group gray.png"
                        alt=""
                        style={{ height: "20px" }}
                      />
                    </Tooltip>
                  ) : text.code == 501 ? (
                    <Tooltip
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.p.partiallyDamagedCard}
                        </LangContext.Consumer>
                      }
                    >
                      <img
                        src="/img/Group gray.png"
                        alt=""
                        style={{ height: "20px" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <LangContext.Consumer>
                          {(i18n) => i18n.s.slideWrongCardType}
                        </LangContext.Consumer>
                      }
                    >
                      <img
                        src="/img/Group gray.png"
                        alt=""
                        style={{ height: "20px" }}
                      />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.e.engineOff}
                      </LangContext.Consumer>
                    }
                  >
                    <img
                      src="/img/Group red.png"
                      alt=""
                      style={{ height: "20px" }}
                    />
                  </Tooltip>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.u.updateLocation} placement="left">
                {i18n.u.updateLocation}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "latest_pos_update_at",
        className: "column-text-center",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "latest_pos_update_at",
              100,
              this.props.auth.profile.config
            )
          : 100,
        render(text, record, index) {
          return {
            children:
              text == "now" ? (
                <div>
                  <LangContext.Consumer>
                    {(i18n) => i18n.n.now}
                  </LangContext.Consumer>
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  {helper_convertDateformat(
                    text,
                    "YYYY-MM-DD HH:mm",
                    "DD/MM/YY HH:mm"
                  )}
                </div>
              ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.c.connection} placement="left">
                {i18n.c.connection}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "gps_active_at",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("gps_active_at", 120, this.props.auth.profile.config)
          : 120,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children:
              text == "now" ? (
                <div>
                  <LangContext.Consumer>
                    {(i18n) => i18n.n.now}
                  </LangContext.Consumer>
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  {helper_convertDateformat(
                    text,
                    "YYYY-MM-DD HH:mm",
                    "DD/MM/YY HH:mm"
                  )}
                </div>
              ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.b.boxID} placement="left">
                {i18n.b.boxID}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("device_code", 120, this.props.auth.profile.config)
          : 120,
        ellipsis: true,
        dataIndex: "device_code",
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.m.meterStatus} placement="left">
                {i18n.m.meterStatus}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "meter_status",
        className: "column-text-center",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("meter_status", 100, this.props.auth.profile.config)
          : 120,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.v.vehicleCode} placement="left">
                {i18n.v.vehicleCode}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        ellipsis: true,
        dataIndex: "vehicle_code",
        className: "column-text-center",
        sorter: (a, b) => ("" + a.vehicle_code).localeCompare(b.vehicle_code),
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("vehicle_code", 150, this.props.auth.profile.config)
          : 150,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.currentAddress}
          </LangContext.Consumer>
        ),
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "current_location",
              400,
              this.props.auth.profile.config
            )
          : 400,
        ellipsis: true,
        dataIndex: "current_location",
        render(text, record, index) {
          if (text != null) {
            return {
              children: (
                <div>
                  <p style={{ marginBottom: "0" }}>
                    {text.map((ele) => (
                      <span>
                        {ele.base_color_code != "" &&
                        ele.base_color_code != null ? (
                          <Tooltip
                            title={ele.name}
                            placement="left"
                            style={{ fontSize: "9px" }}
                          >
                            <Tag
                              className="current-location-tracking-style"
                              color={`${ele.base_color_code}`}
                            >
                              {ele.name}
                            </Tag>
                          </Tooltip>
                        ) : (
                          <Tooltip title={ele.name} placement="left">
                            {ele.name}
                          </Tooltip>
                        )}
                      </span>
                    ))}
                  </p>
                </div>
              ),
            };
          } else {
            return {
              children: <p></p>,
            };
          }
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.mixingDrum}
          </LangContext.Consumer>
        ),
        dataIndex: "drum_status",
        className: "column-text-center",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("drum_status", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.b.battery} placement="left">
                {i18n.b.battery}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "battery",
        className: "column-text-center",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("battery", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text == "-" ? text : `${text}%`}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.b.battery_status} placement="left">
                {i18n.b.battery_status}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "is_charging",
        className: "column-text-center",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("is_charging", 150, this.props.auth.profile.config)
          : 150,
        render(text, record, index) {
          return {
            children: (
              <div>
                {text == true ? (
                  <Tooltip
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.battery_is_charge}
                      </LangContext.Consumer>
                    }
                    placement="left"
                  >
                    <img
                      src="/img/map/status_icon/16_16/16battery-green.png"
                      alt=""
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <LangContext.Consumer>
                        {(i18n) => i18n.b.battery_not_charge}
                      </LangContext.Consumer>
                    }
                    placement="left"
                  >
                    <img
                      src="/img/map/status_icon/16_16/16battery-grey.png"
                      alt=""
                    />
                  </Tooltip>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.f.fuel}</LangContext.Consumer>
        ),
        dataIndex: "fuel_status",
        className: "column-text-center",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("fuel_status", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.temperature1}
          </LangContext.Consumer>
        ),
        dataIndex: "temperature1",
        ellipsis: true,
        className: "column-text-center",
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("temperature1", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.temperature2}
          </LangContext.Consumer>
        ),
        dataIndex: "temperature2",
        ellipsis: true,
        className: "column-text-center",
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("temperature2", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.p.pto}</LangContext.Consumer>
        ),
        dataIndex: "PTO",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("PTO", 90, this.props.auth.profile.config)
          : 90,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: (
              <div>
                {!text ? (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.open}
                  </LangContext.Consumer>
                ) : (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.close}
                  </LangContext.Consumer>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.g.gate1}</LangContext.Consumer>
        ),
        dataIndex: "door1",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("door1", 140, this.props.auth.profile.config)
          : 140,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: (
              <div>
                {!text ? (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.open}
                  </LangContext.Consumer>
                ) : (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.close}
                  </LangContext.Consumer>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.g.gate2}</LangContext.Consumer>
        ),
        dataIndex: "door2",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("door2", 140, this.props.auth.profile.config)
          : 140,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: (
              <div>
                {!text ? (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.open}
                  </LangContext.Consumer>
                ) : (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.close}
                  </LangContext.Consumer>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.g.gate3}</LangContext.Consumer>
        ),
        dataIndex: "door3",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("door3", 140, this.props.auth.profile.config)
          : 140,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: (
              <div>
                {!text ? (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.open}
                  </LangContext.Consumer>
                ) : (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.close}
                  </LangContext.Consumer>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.b.BOOM}</LangContext.Consumer>
        ),
        dataIndex: "boom",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("boom", 140, this.props.auth.profile.config)
          : 140,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: (
              <div>
                {!text ? (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.open}
                  </LangContext.Consumer>
                ) : (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.close}
                  </LangContext.Consumer>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: "ROM",
        dataIndex: "sdcard_available1",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "sdcard_available1",
              150,
              this.props.auth.profile.config
            )
          : 150,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: (
              <div>{record.sdcard_available1 + "/" + record.sdcard_total1}</div>
            ),
          };
        },
      },
      {
        title: "SD CARD",
        dataIndex: "sdcard_available2",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "sdcard_available2",
              150,
              this.props.auth.profile.config
            )
          : 150,
        className: "column-text-center",
        render(text, record, index) {
          return {
            children: (
              <div>{record.sdcard_available2 + "/" + record.sdcard_total2}</div>
            ),
          };
        },
      },
      {
        title: "SD CARD STATUS",
        dataIndex: "sdcard_status",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("sdcard_status", 150, this.props.auth.profile.config)
          : 150,
        className: "column-text-center",
        render(text, record, index) {
          let sdcard_status = "none";
          if (record.sdcard_status == true) {
            sdcard_status = "normal";
          }
          if (record.sdcard_status == false) {
            sdcard_status = "broken";
          }
          return {
            children: (
              <div>
                {
                  <LangContext.Consumer>
                    {(i18n) => i18n.sdcardStatus[sdcard_status]}
                  </LangContext.Consumer>
                }
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.d.devicePhone} placement="left">
                {i18n.d.devicePhone}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        dataIndex: "vehicle_tel",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("vehicle_tel", 160, this.props.auth.profile.config)
          : 160,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.picture}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "img",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("img", 160, this.props.auth.profile.config)
          : 160,
        render(text, record, index) {
          return {
            children: (
              <div>
                <a href={text} target="_blank" alt="image">
                  <Icon type="cloud-download" />
                </a>
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.s.sos}</LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "is_sos_should_alert",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "is_sos_should_alert",
              160,
              this.props.auth.profile.config
            )
          : 160,
        render(text, record, index) {
          return {
            children: (
              <div>
                {text == null || text == 0 ? (
                  ""
                ) : (
                  <img src="/img/map/status_icon/16_16/16sos-red.png" alt="" />
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.p.pictureUpdate} placement="left">
                {i18n.p.pictureUpdate}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "gps_image_active_at",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "gps_image_active_at",
              160,
              this.props.auth.profile.config
            )
          : 160,
        render(text, record, index) {
          return {
            children:
              text == "now" ? (
                <div>{text}</div>
              ) : (
                <div>
                  {helper_convertDateformat(
                    text,
                    "YYYY-MM-DD HH:mm",
                    "DD/MM/YY HH:mm"
                  )}
                </div>
              ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.d.deliveryStatus} placement="left">
                {i18n.d.deliveryStatus}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        ellipsis: true,
        dataIndex: "LDT",
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("LDT", 180, this.props.auth.profile.config)
          : 180,
        render(text, record, index) {
          return {
            children: (
              <div>
                {text ? (
                  <LangContext.Consumer>
                    {(i18n) => i18n.o.open}
                  </LangContext.Consumer>
                ) : (
                  <LangContext.Consumer>
                    {(i18n) => i18n.c.close}
                  </LangContext.Consumer>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.c.company}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "company_name",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("company_name", 200, this.props.auth.profile.config)
          : 200,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      //ระยะเวลาสถานะ
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.d.durationRange}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "duration_range",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("duration_range", 200, this.props.auth.profile.config)
          : 200,
        render(text, record, index) {
          return {
            children: <div>{text != "" ? text : "?"}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.moisture}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "humidity",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("humidity", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.m.moisture + "2"}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "humidity2",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("humidity2", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.r.rpm}</LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "RPM",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("RPM", 60, this.props.auth.profile.config)
          : 60,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.o.obd}</LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "OBD",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("OBD", 60, this.props.auth.profile.config)
          : 60,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.t.tripStatus}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "trip_status",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("trip_status", 160, this.props.auth.profile.config)
          : 160,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.p.plant}</LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "plant",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("plant", 160, this.props.auth.profile.config)
          : 160,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.p.passenger}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "passenger_count",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "passenger_count",
              160,
              this.props.auth.profile.config
            )
          : 160,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleModel}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "model",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("model", 160, this.props.auth.profile.config)
          : 160,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.s.satelliteCount}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "satellite",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("satellite", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text == "-1" ? "N/A" : text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.s.sim}</LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "sim",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("sim", 160, this.props.auth.profile.config)
          : 160,
        render(text, record, index) {
          return {
            children: <div>{text["signal"]}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.s.startTrip}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "trip_status",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("trip_status", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.e.eventAlarm} placement="left">
                {i18n.e.eventAlarm}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "alarmMessage",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("alarmMessage", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.e.estTransitDistance} placement="left">
                {i18n.e.estTransitDistance}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "travelDistance",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("travelDistance", 150, this.props.auth.profile.config)
          : 150,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.v.vehicleColor}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "color",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("color", 100, this.props.auth.profile.config)
          : 100,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => (
              <Tooltip title={i18n.l.latestDriver} placement="left">
                {i18n.l.latestDriver}
              </Tooltip>
            )}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "lastest_driver",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("lastest_driver", 180, this.props.auth.profile.config)
          : 180,
        render(text, record, index) {
          return {
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>{(i18n) => i18n.v.videos}</LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "video",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("video", 130, this.props.auth.profile.config)
          : 130,
        render(text, record, index) {
          return {
            children: (
              <div>
                {text.length == 2 ? (
                  <div>
                    <a
                      href={text[0]}
                      target="_blank"
                      alt="image"
                      style={{ paddingRight: "2px" }}
                    >
                      <Icon type="cloud-download" />
                    </a>
                    <a href={text[1]} target="_blank" alt="image">
                      <Icon type="cloud-download" />
                    </a>
                  </div>
                ) : text.length == 1 ? (
                  <a href={text[0]} target="_blank" alt="image">
                    <Icon type="cloud-download" />
                  </a>
                ) : (
                  ""
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.e.electricityUse}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "pw_supply",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth("pw_supply", 130, this.props.auth.profile.config)
          : 130,
        render(text, record, index) {
          let tooltipMessage = "";
          let source = "";
          let sourceFromVehicle = "";
          let sourceAc = "";
          let tooltipMessageFromAc = (
            <LangContext.Consumer>
              {(i18n) => i18n.p.powerBank}
            </LangContext.Consumer>
          );
          let tooltipMessageFromVehicle = (
            <LangContext.Consumer>
              {(i18n) => i18n.p.powerFromVehicle}
            </LangContext.Consumer>
          );

          if (!!record.pw_supply) {
            if (
              typeof record.pw_supply["batt"] !== "undefined" &&
              Array.isArray(record.pw_supply["batt"])
            ) {
              if (record.pw_supply["batt"][0] == 1) {
                tooltipMessage = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.p.present}
                  </LangContext.Consumer>
                );
                source = "/img/battery-on.png";
              } else if (record.pw_supply["batt"][0] == 0) {
                tooltipMessage = (
                  <LangContext.Consumer>
                    {(i18n) => i18n.n.nope}
                  </LangContext.Consumer>
                );
                source = "/img/battery-off.png";
              }

              if (record.pw_supply["current_src"] == "DC") {
                sourceFromVehicle = "/img/Battery-formcar-on.png";
                sourceAc = "/img/battery-off.png";
              } else {
                sourceFromVehicle = "/img/Battery-formcar-off.png";
                sourceAc = "/img/battery-on.png";
              }
            }
          }

          return {
            children: (
              <div style={{ position: "relative", height: 30 }}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {!!record.pw_supply ? (
                    <>
                      <Tooltip title={tooltipMessage} placement="top">
                        <img src={source} alt="" style={{ height: "10px" }} />
                      </Tooltip>{" "}
                      /{" "}
                      <Tooltip title={tooltipMessageFromAc} placement="top">
                        <img
                          src={sourceAc}
                          alt=""
                          style={{ height: "10px", marginRight: 4 }}
                        />
                      </Tooltip>{" "}
                      <Tooltip
                        title={tooltipMessageFromVehicle}
                        placement="top"
                      >
                        <img
                          src={sourceFromVehicle}
                          alt=""
                          style={{ height: "14px", marginBottom: 4 }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
                {!!record.pw_supply && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-5px",
                      right: "-3px",
                    }}
                  >
                    <label style={{ fontSize: 8 }}>
                      {!!record.pw_supply ? (
                        typeof record.pw_supply["dc_vol"] !== "undefined" &&
                        Array.isArray(record.pw_supply["dc_vol"]) &&
                        typeof record.pw_supply["dc_vol"][1] !== "undefined" &&
                        (record.pw_supply["dc_vol"][1] == -1 ||
                          record.pw_supply["dc_vol"][1] == 9.999) ? (
                          "- "
                        ) : typeof record.pw_supply["batt"] !== "undefined" &&
                          Array.isArray(record.pw_supply["batt"]) &&
                          typeof record.pw_supply["batt"][1] !== "undefined" ? (
                          <>
                            <label
                              style={{
                                color:
                                  record.pw_supply["current_src"] != "DC"
                                    ? "#2DCE98"
                                    : "#CBCBCB",
                              }}
                            >
                              {(
                                parseInt(
                                  record.pw_supply["batt"][1] * Math.pow(10, 2)
                                ) / Math.pow(10, 2)
                              ).toFixed(2)}{" "}
                              V
                            </label>{" "}
                            /{" "}
                            <label
                              style={{
                                color:
                                  record.pw_supply["current_src"] == "DC"
                                    ? "#2DCE98"
                                    : "#CBCBCB",
                              }}
                            >
                              {(
                                parseInt(
                                  record.pw_supply["dc_vol"][1] *
                                    Math.pow(10, 2)
                                ) / Math.pow(10, 2)
                              ).toFixed(2)}{" "}
                              V
                            </label>
                          </>
                        ) : (
                          "-"
                        )
                      ) : (
                        "- "
                      )}
                    </label>
                  </div>
                )}
              </div>
            ),
          };
        },
      },
      //สถานะการบิดกุญแจ
      {
        title: (
          <LangContext.Consumer>
            {(i18n) => i18n.e.engineVolStatus}
          </LangContext.Consumer>
        ),
        className: "column-text-center",
        dataIndex: "engine_vol_status",
        ellipsis: true,
        width: this.checkWidth(this.props.auth.profile.config)
          ? this.getWidth(
              "engine_vol_status",
              150,
              this.props.auth.profile.config
            )
          : 150,
        render(text, record, index) {
          let engine_vol_status_text = "-";
          if (record.engine_vol_status) {
            engine_vol_status_text = self.getKeyTurningStatus(
              record.engine_vol_status
            );
          }

          return {
            children: <div>{engine_vol_status_text}</div>,
          };
        },
      },
    ];
    let result = [];
    if (
      columns.includes("sdcard_available1") &&
      !columns.includes("sdcard_available2")
    ) {
      columns.push("sdcard_available2");
    }

    if(columns.includes("meter_status")) {
      // filter meter_fare ตัวเดิมออกก่อน
      columns = columns.filter((x) => x !== "meter_fare");

      // เพิ่ม meter_fare เข้าไป
      columns = [...columns.slice(0, 2), "meter_fare", ...columns.slice(2)];
    }

    columns.map((ele) => {
      let col = allCol.find((x) => {
        return x.dataIndex === ele;
      });
      if (typeof col !== "undefined") result.push(col);
    });

    result.push();
    result.unshift(indexCol);
    return result;
  };
  async getLocationFromMapserver(record) {
    if (typeof record == "undefined" || record == null) {
      return "";
    }

    if (record.isVehicleMoving) {
      if (record.current_location.length == 0) {
        let response = await fetch(
          // `http://login.terminusfleet.com/service/offline_map/request.php?lat=${ele.latest_lat}&lng=${ele.latest_lng}`
          `https://maps.terminusfleet.com/reverse.php?format=jsonv2&lat=13.723913&lon=100.549419&zoom=21&accept-language=th`
        );

        let data = await response.json();
        let name = data.display_name;

        return name;
      } else {
        return record.current_location.map((ele) => ele.name).join(", ");
      }
    } else {
      return record.current_location.map((ele) => ele.name).join(", ");
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.loadedDateTime != nextProps.loadedDateTime ||
      this.state.columnsLastestAdjust != nextState.columnsLastestAdjust ||
      this.props.latest_config_change != nextProps.latest_config_change
    ) {
      if (this.props.latest_config_change != nextProps.latest_config_change)
        this.initDynamicColumns();
      return true;
    }
    if (this.props.switchCustomizeTable != nextProps.switchCustomizeTable) {
      return true;
    }
    if (
      this.props.selectedVehicle.vehicle_id !=
      nextProps.selectedVehicle.vehicle_id
    ) {
      return true;
    }

    if (this.checkResize(nextState)) {
      return true;
    }

    if (this.props.splitterLayoutHNT != nextProps.splitterLayoutHNT) {
      return true;
    }

    return false;
  }

  checkResize = (nextState) => {
    if (nextState.columns.length != this.state.columns.length) {
      return true;
    }
    let shouldRerender = false;
    for (let i = 0; i < this.state.columns.length; i++) {
      let item = this.state.columns[i];
      let getNextState = nextState.columns.find(
        (x) => x.dataIndex == item.dataIndex
      );
      if (typeof getNextState != "undefined") {
        if (getNextState.width != item.width) {
          shouldRerender = true;
          break;
        }
      }
    }
    return shouldRerender;
  };

  components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  handleResize = (index) => (e, { size }) => {
    this.setState(
      ({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      },
      () => {
        debounce(
          this.setState(
            {
              showSaveBtn: true,
            },
            () => {
              this.props.handleChangeCustomizeTable(
                this.state.showSaveBtn,
                this.state.columns
              );
            }
          ),
          500
        );
      }
    );
  };

  render() {
    const columns = this.props.switchCustomizeTable
      ? this.state.columns.map((col, index) => ({
          ...col,
          onHeaderCell: (column) => ({
            width: column.width,
            onResize: this.handleResize(index),
          }),
        }))
      : this.state.columns.map((col, index) => ({
          ...col,
        }));

    // console.log("dataSource", this.props.trackingList);
    return (
      <>
        {this.props.switchCustomizeTable ? (
          <Table
            bordered
            rowKey={(record, i) => record.plate_no + "_" + i}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  this.props.openInfoPanel(
                    this.props.trackingList,
                    record.vehicle_id
                  );
                }, // double click row
              };
            }}
            rowClassName={(record, index) => {
              let returnVal = "";
              this.props.selectedVehicle.vehicle_id != "" &&
              record.vehicle_id == this.props.selectedVehicle.vehicle_id
                ? (returnVal = "highlight-row-dark")
                : (returnVal = "");

              if (typeof record.is_sos_should_alert !== undefined) {
                if (record.is_sos_should_alert == 1)
                  returnVal = returnVal + " invalid";
              }

              return returnVal;
            }}
            className="tracking-table-v2"
            components={this.components}
            columns={columns}
            dataSource={this.props.trackingList}
            pagination={false}
            size="default"
            scroll={{
              x: 800,
              y: this.props.splitterLayoutCustomTable
                ? `${window.innerHeight - 530}px`
                : `${window.innerHeight - 175}px`,
            }}
          />
        ) : (
          <ReactDragListView.DragColumn {...this.state.dragProps}>
            <Table
              bordered
              rowKey={(record, i) => record.plate_no + "_" + i}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.props.openInfoPanel(
                      this.props.trackingList,
                      record.vehicle_id
                    );
                  }, // double click row
                };
              }}
              rowClassName={(record, index) => {
                let returnVal = "";
                this.props.selectedVehicle.vehicle_id != "" &&
                record.vehicle_id == this.props.selectedVehicle.vehicle_id
                  ? (returnVal = "highlight-row-dark")
                  : (returnVal = "");

                if (typeof record.is_sos_should_alert !== undefined) {
                  if (record.is_sos_should_alert == 1)
                    returnVal = returnVal + " invalid";
                }

                return returnVal;
              }}
              className="tracking-table-v2"
              columns={columns}
              dataSource={this.props.trackingList}
              pagination={false}
              size="default"
              scroll={{
                x: 800,
                y: this.props.splitterLayoutHNT
                  ? `${window.innerHeight - 530}px`
                  : `${window.innerHeight - 175}px`,
              }}
            />
          </ReactDragListView.DragColumn>
        )}
      </>
    );
  }
}

export default CustomTable;
