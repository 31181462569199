import React, { Component, Fragment } from "react";
import {
  TreeSelect,
  Select,
  Form,
  Input,
  Slider,
  Spin,
  Modal,
  Button,
} from "antd";
import {
  speedRangeMarks,
  timeRangeMarks,
} from "../../../../constants/constants";
import LangContext, { i18n } from "modules/shared/context/langContext";
const Option = Select.Option;
const { TextArea } = Input;

export class FilterModal extends Component {
  state = {
    speedRange: this.props.DEFAULT_FILTER_VALUE.speedRange,
    timeRange: this.props.DEFAULT_FILTER_VALUE.timeRange,
  };
  render() {
    return (
      <Modal
        forceRender={true}
        style={{ top: 20 }}
        title={
          <LangContext.Consumer>{(i18n) => i18n.f.filter}</LangContext.Consumer>
        }
        visible={this.props.filterVisible}
        onCancel={this.props.handleFilterCancel}
        footer={[
          <Button key="back" onClick={this.props.handleFilterCancel}>
            <LangContext.Consumer>
              {(i18n) => i18n.c.cancel}
            </LangContext.Consumer>
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.props.monitoringState.trackingLoading}
            onClick={this.props.handleApplayButtonClick}
          >
            {" "}
            <LangContext.Consumer>
              {(i18n) => i18n.a.apply}
            </LangContext.Consumer>
          </Button>,
        ]}
      >
        <Spin spinning={false}>
          <Form layout="horizontal">
            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.p.plateNo}
                </LangContext.Consumer>
              }
            > 
              <TextArea
                allowClear={true}
                onChange={this.props.handleSearchTextboxChange}
                value={this.props.searchName}
                />
            </Form.Item>

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.d.deviceType}
                </LangContext.Consumer>
              }
            >
              <Select
                mode="multiple"
                defaultValue={this.props.DEFAULT_FILTER_VALUE.deviceType}
                width="100%"
                style={{ width: "100%" }}
                onChange={this.props.handleDeviceTypeChange}
                value={this.props.deviceType}
              >
                {this.props.deviceTypeList.map((value) => (
                  <Option key={value.id} value={value.id}>
                    {value.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.g.groupVehicleType}
                </LangContext.Consumer>
              }
            >
              <TreeSelect
                value={this.props.masterDataSelectvalue}
                blur={this.props.handleMasterDataTreeUnfocus}
                focus={this.props.handleMasterDataTreeUnfocus}
                {...this.props.masterDataTreeProps}
              />
            </Form.Item>

            <Form.Item
              label={
                <LangContext.Consumer>
                  {(i18n) => i18n.v.vehicleStatus}
                </LangContext.Consumer>
              }
            >
              <Select
                mode="multiple"
                defaultValue={this.props.DEFAULT_FILTER_VALUE.vehicleStatus}
                width="100%"
                style={{ width: "100%" }}
                onChange={this.props.handleVehicleStatusSelectboxChange}
                value={this.props.vehicleStatus}
              >
                <Option key="running" value="running">
                  {
                    <LangContext.Consumer>
                      {(i18n) => i18n.r.running}
                    </LangContext.Consumer>
                  }
                </Option>
                <Option key="stop-with-engine-on" value="stop-with-engine-on">
                  {
                    <LangContext.Consumer>
                      {(i18n) => i18n.s.stopWithEngineOn}
                    </LangContext.Consumer>
                  }
                </Option>
                <Option key="stop-with-engine-off" value="stop-with-engine-off">
                  {
                    <LangContext.Consumer>
                      {(i18n) => i18n.e.engineOff}
                    </LangContext.Consumer>
                  }
                </Option>
                <Option key="connection-error" value="connection-error">
                  {
                    <LangContext.Consumer>
                      {(i18n) => i18n.l.lostConnectio}
                    </LangContext.Consumer>
                  }
                </Option>
              </Select>
            </Form.Item>

            {this.props.vehicleStatus.filter((ele) => ele == "running")
              .length == 1 && (
              <Form.Item
                label={
                  <LangContext.Consumer>
                    {(i18n) => i18n.s.speedRange}
                  </LangContext.Consumer>
                }
              >
                <Slider
                  range
                  max={200}
                  marks={speedRangeMarks}
                  defaultValue={this.props.DEFAULT_FILTER_VALUE.speedRange}
                  value={this.props.speedRange}
                  onChange={this.props.handleSpeedRangeChange}
                />
              </Form.Item>
            )}
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default FilterModal;
