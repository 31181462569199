import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tabs, Modal } from "antd";
import * as moment from "moment";

import { GeneralStyledContent } from "../../../../styled/common-styled";
import Breadcrumb from "./../../../shared/components/Breadcrumb";
import LangContext from "modules/shared/context/langContext";
import ChooseFile from "./ChooseFile";
import AllData from "./AllData";
import ModalVideoLog from "./ModalVideoLog";
import AuthorizeComponent from "./../../../auth/components/AuthorizeComponent";

import * as helper from "./helperFx";
import {
  createAxios,
  fetchData,
  createQueue,
  cancelQueue,
  reQueue,
  deleteQueue,
  fetchAllConnection,
  fetchDeviceProcess,
  createQueues,
  updateAutoDownload,
} from "./apiClient";
import {
  BREAKPOINTS,
  RESPONSIVE_COMPARE,
  withResponsive,
} from "../../../shared/context/ResponsiveContext";

const { TabPane } = Tabs;

class Index extends Component {
  state = {
    idprocess: "web" + new Date().getTime(),
    loading: false,
    queues: [],
    listfiles: [],
    updateAt: "",
    deviceProcess: null,
    idconnect: "",
    filter: {
      vehicle_id: "",
      plate_no: "",
      cameraPosition: "",
      daterange: "",
      date_start: "",
      date_end: "",
      old_format: false,
    },
    vehicle_id_alldata: "",
    plate_no_alldata: "",
    log_video_file: "",
    isModalVideoLogOpen: false,
    log_video_file_loading: false,
    plate_no_selected: "",
    on_form_submit: false,
    camera_channel: [],
  };

  connectWebsocket = () => {
    this.ws = new WebSocket(
      `wss://${process.env.REACT_APP_WEBSOCKET}/ws?idprocess=` +
        this.state.idprocess
    );

    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("connected websocket htn");
    };

    this.ws.onmessage = (evt) => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)
      this.handleReceiveWebsocketMsg(evt);
    };

    this.ws.onclose = (e) => {
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second." + e.code
      );
      setTimeout(() => {
        this.connectWebsocket();
      }, 1000);
    };

    this.ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      this.ws.close();
    };
  };

  handleReceiveWebsocketMsg = (evt) => {
    var messages = evt.data.split("\n");

    for (var i = 0; i < messages.length; i++) {
      let obj = JSON.parse(messages[i]);
      let imei =
        typeof obj.Idprocess === "undefined" ? obj.IdProcess : obj.Idprocess;
      let connectId =
        typeof obj.Idconnect === "undefined" ? obj.IdConnect : obj.Idconnect;

      if (imei === "" && connectId === "") {
        continue;
      }

      let data = JSON.parse(obj.Data);
      if (typeof data.type !== "undefined") {
        //old firmware, new firmware
        if (["get_list_video", "getListVideo"].includes(data.type)) {
          const { plate_no, vehicle_id, old_format } = this.state.filter;

          let listfile = helper.genListFile(
            data,
            imei,
            plate_no,
            vehicle_id,
            old_format,
            this.state.queues
          );

          console.log("data", data.files.length, listfile.length);

          this.setState({ listfiles: listfile, on_form_submit: false }, () => {
            this.handleLoadQueue(true);
          });
        } else if (["get_all_video"].includes(data.type)) {
          this.setState({ log_video_file_loading: false });

          if (data) {
            let groupDate = this.groupedByDate(data);

            this.setState({
              log_video_file: groupDate,
            });
          }
        }
      }
    }
  };
  // Grouping function
  groupedByDate = (data) => {
    const groupedData = {};

    data.files.forEach((file) => {
      // Extract date (YYYY-MM-DD) from create_date
      const date = file.create_date.split(" ")[0];

      // Initialize date group if not exists
      if (!groupedData[date]) {
        groupedData[date] = {};
      }

      // Initialize camera_source group if not exists
      if (!groupedData[date][file.camera_source]) {
        groupedData[date][file.camera_source] = [];
      }

      // Push the file into the corresponding date → camera_source array
      groupedData[date][file.camera_source].push(file);
    });

    return groupedData;
  };

  handleLoadQueue = async (showloading) => {
    if (this.state.on_form_submit) {
      return;
    }
    if (showloading) {
      this.setState({ loading: true });
    }

    let params = {
      vehicle_visibility: this.props.auth.profile.vehicle_visibility,
      company_id: this.props.auth.profile.company_id,
      vehicles_id: "",
      start_at: "",
      end_at: "",
      camera_source: "",
      plate_no: "",
    };

    fetchData(params, (statusCode, response) => {
      if (statusCode !== 200) {
        this.setState({ loading: false, queues: [] });
        return;
      }
      //debugger
      let updateList = this.state.listfiles.map((x) => {
        //let nw = response.find(r => r.id === x.queue_id)
        let nw = response.find(
          (r) =>
            r.filename === x.filename &&
            r.hwid == x.hwid &&
            r.camera_source.toString() == x.camera_source_idx.toString()
        );
        if (typeof nw !== "undefined") {
          return {
            ...x,
            queue_id: nw.id,
            download_progress: nw.download_progress,
            queue_status: nw.queue_status,
            download_link: nw.download_link,
            remark: nw.remark,
          };
        }
        // case data โดนลบ
        if (x.queue_id !== 0) {
          return {
            ...x,
            queue_id: 0,
            download_progress: 0,
            queue_status: 0,
            download_link: "",
            remark: "",
          };
        }
        return x;
      });

      let queues = response;
      if (this.state.vehicle_id_alldata !== "") {
        queues = queues
          .filter(
            (x) =>
              parseInt(x.vehicles_id) ===
              parseInt(this.state.vehicle_id_alldata)
          )
          .map((x, i) => {
            x.row_number = i + 1;
            return x;
          });
      }
      this.setState(
        {
          loading: false,
          queues: queues,
          updateAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
          listfiles: updateList,
        },
        () => this.handleDownloadFile(response)
      );
    });
  };

  handleFilterFile = (vehicleId, plate_no) => {
    this.setState(
      { vehicle_id_alldata: vehicleId, plate_no_alldata: plate_no },
      () => this.handleLoadQueue(true)
    );
  };

  handleDownloadFile = (data) => {
    data.forEach((ele) => {
      if (
        ele.download_link !== null &&
        ele.auto_download_time === "" &&
        ele.id_process === this.state.idprocess
      ) {
        helper.downloadFileVideo(ele.download_link);
        updateAutoDownload(ele.id);
      }
    });
  };

  handleLoadListFileFromDevice = (values) => {
    const {
      imei,
      eventdate,
      plate_no,
      camera_position,
      vehicle_id,
      file_size,
      old_format,
    } = values;

    const date_start = moment(eventdate[0]["_d"]).format("YYYY-MM-DD HH:mm:ss");
    const date_end = moment(eventdate[1]["_d"]).format("YYYY-MM-DD HH:mm:ss");

    let data = {
      date_start: date_start,
      date_end: date_end,
      camera_source: camera_position,
      file_size: file_size,
    };

    let message = {
      Idconnect: imei,
      Idprocess: this.state.idprocess,
    };

    // if (!old_format) {
    //   data.type = "getListVideo"
    //   message.Key = ""
    //   message.CmdType = "getListVideo"
    //   message.Data = JSON.stringify(data)
    //   console.log("new firmware", message)
    //   this.ws.send(JSON.stringify(message));
    // }

    // if (old_format) {
    data.type = "get_list_video";
    message.Data = JSON.stringify(data);
    //console.log("old firmware", message);
    this.ws.send(JSON.stringify(message));
    // }
    let filter = {
      vehicle_id: vehicle_id,
      plate_no: plate_no,
      cameraPosition: camera_position,
      file_size: file_size,
      daterange: date_start + " - " + date_end,
      date_start,
      date_end,
      old_format,
    };

    this.setState({
      loading: true,
      listfiles: [],
      idconnect: imei,
      filter: filter,
      vehicle_id_alldata: vehicle_id,
      plate_no_alldata: plate_no,
      on_form_submit: true,
    });

    fetchDeviceProcess(imei, (data) => {
      const { cntDownload, cntLivestream, cntPlayback } = data;
      let total = cntDownload + cntLivestream + cntPlayback;
      let result = data;
      if (total === 0) {
        result = null;
      }

      this.setState({ deviceProcess: result });
    });
  };
  loadLastestFileVehicle = (imei, plate_no) => {
    let list = JSON.stringify({
      Idconnect: imei,
      Idprocess: this.state.idprocess,
      Data: JSON.stringify({ type: "get_all_video" }),
    });

    console.log("list", list);
    // if (old_format) {
    this.setState({
      isModalVideoLogOpen: true,
      log_video_file_loading: true,
      plate_no_selected: plate_no,
      log_video_file: "",
    });
    this.ws.send(list);
  };
  handleCreateQueue = async (record) => {
    this.setState({ loading: true });
    const params = {
      vehicles_id: parseInt(record.vehicle_id),
      hwid: record.hwid,
      filename: record.filename,
      file_size: parseInt(record.original_size),
      file_datetime: record.original_date,
      camera_source: record.camera_source,
      queue_status: helper.queueStatusId("waiting"),
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
      file_option: this.state.filter.file_size,
    };

    fetchAllConnection((allimei) => {
      if (allimei.includes(record.hwid)) {
        createQueue(params, this.handleCallbackQueueAction);
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถดาวน์โหลดได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleLoadMultiple = async (selectRows) => {
    let items = [];
    let hwid = "";
    this.setState({ loading: true });
    this.state.listfiles.map((record) => {
      if (
        selectRows.includes(
          record.filename + "_" + record.camera_source.toString()
        )
      ) {
        hwid = record.hwid;
        const params = {
          vehicles_id: parseInt(record.vehicle_id),
          hwid: record.hwid,
          filename: record.filename,
          file_size: parseInt(record.original_size),
          file_datetime: record.original_date,
          camera_source: record.camera_source,
          queue_status: helper.queueStatusId("waiting"),
          user_id: this.props.auth.profile.id,
          id_process: this.state.idprocess,
          file_option: this.state.filter.file_size,
        };

        items = [...items, params];
      }
      return;
    });

    fetchAllConnection((allimei) => {
      if (allimei.includes(hwid)) {
        createQueues({ data: items }, () => {
          this.handleLoadQueue(true);
        });
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถดาวน์โหลดได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleReQueue = async (queueId, hwid, filename) => {
    this.setState({ loading: true });
    let params = {
      id: queueId,
      hwid,
      filename,
      queue_status: helper.queueStatusId("downloading"),
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
    };

    fetchAllConnection((allimei) => {
      if (allimei.includes(hwid)) {
        reQueue(params, this.handleCallbackQueueAction);
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถดาวน์โหลดได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleCancelQueue = async (queueId, hwid, filename) => {
    this.setState({ loading: true });
    let params = {
      id: queueId,
      hwid,
      filename,
      queue_status: helper.queueStatusId("cancel"),
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
    };

    fetchAllConnection((allimei) => {
      if (allimei.includes(hwid)) {
        cancelQueue(params, this.handleCallbackQueueAction);
      } else {
        Modal.error({
          title: "แจ้งเตือน",
          content: "ไม่สามารถยกเลิกได้ เนื่องจากอุปกรณ์ออฟไลน์อยู่",
        });
        this.setState({ loading: false });
      }
    });
  };

  handleDeleteQueue = (queueId, hwid, filename) => {
    this.setState({ loading: true });
    let params = {
      id: queueId,
      hwid,
      filename,
      queue_status: 0,
      user_id: this.props.auth.profile.id,
      id_process: this.state.idprocess,
    };

    deleteQueue(params, this.handleCallbackQueueAction);
  };
  setCameraChannel = (camera_channel) => {
    this.setState({ camera_channel: camera_channel });
  };
  handleCallbackQueueAction = (statusCode, response) => {
    if (statusCode !== 200) {
      this.setState({ loading: false });
      return;
    }

    this.handleLoadQueue(true);

    // const { params, msg, download_link, data } = response.data;

    // if (msg === "file exists") {
    //   window.location = download_link;
    //   return;
    // }

    // let updateData = {
    //   download_progress: 0,
    //   queue_status: params.queue_status,
    //   download_link: "",
    // };

    // /// case create
    // if (typeof data !== "undefined") {
    //   updateData.queue_id = data.id;
    // }

    // let filename = params.filename;
    // let imei = params.hwid;

    // let index = this.state.listfiles.findIndex(
    //   (x) =>
    //     x.filename == filename &&
    //     x.hwid == imei &&
    //     x.camera_source == params.camera_source
    // );
    // let newData = helper.updateFileList(
    //   this.state.listfiles,
    //   index,
    //   updateData
    // );

    // let newQueue = [];
    // if (params.queue_status === 0) {
    //   // delete case
    //   newQueue = this.state.queues.filter(
    //     (x) => !(x.filename == filename && x.hwid == imei)
    //   );
    // } else {
    //   index = this.state.queues.findIndex(
    //     (x) => x.filename == filename && x.hwid == imei
    //   );
    //   newQueue = helper.updateFileList(this.state.queues, index, updateData);
    // }

    // newQueue = newQueue.map((x, idx) => {
    //   x.row_number = idx + 1;
    //   return x;
    // });

    // this.setState({
    //   listfiles: newData,
    //   queues: newQueue,
    // });
  };

  handleCheckProcess = () => {
    if (this.state.idconnect === "") {
      return;
    }

    fetchDeviceProcess(this.state.idconnect, (data) => {
      const { cntDownload, cntLivestream, cntPlayback } = data;
      let total = cntDownload + cntLivestream + cntPlayback;
      let result = data;
      if (total === 0) {
        result = null;
      }
      this.setState({
        deviceProcess: result,
      });
    });
  };
  handleModalVideoLogClose = () => {
    this.setState({ isModalVideoLogOpen: false });
  };

  componentDidMount() {
    createAxios(this.props.auth.accessToken);
    this.handleLoadQueue(true);
    this.connectWebsocket();

    this.interval = setInterval(() => {
      this.handleLoadQueue(false);
      this.handleCheckProcess();
    }, 1000 * 15);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { isBreakpoint } = this.props;
    return (
      <AuthorizeComponent matching_name="download_video_honeytoast">
        <GeneralStyledContent
          style={{
            ...(isBreakpoint(RESPONSIVE_COMPARE.DOWN, BREAKPOINTS.XS) && {
              padding: "20px 5px",
            }),
          }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Breadcrumb
                match={this.props.match}
                style={{ margin: "0px 0px 14px 10px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} type="flex">
            <Col span={24}>
              <LangContext>
                {(i18n) => (
                  <Tabs defaultActiveKey="1" style={{ minHeight: 650 }}>
                    <TabPane tab={i18n.s.searchFile} key="1">
                      <ChooseFile
                        loading={this.state.loading}
                        dataSource={this.state.listfiles}
                        onSubmit={this.handleLoadListFileFromDevice}
                        deviceProcess={this.state.deviceProcess}
                        handleLoadMultiple={this.handleLoadMultiple}
                        loadLastestFileVehicle={this.loadLastestFileVehicle}
                        logVideoFile={this.state.log_video_file}
                        isModalVideoLogOpen={this.handleModalVideoLogOpen}
                        isModalVideoLogClose={this.handleModalVideoLogClose}
                        isModalOpen={this.state.isModalVideoLogOpen}
                        loadingLogVideoFile={this.state.log_video_file_loading}
                        setCameraChannel={this.setCameraChannel}
                        action={{
                          create: this.handleCreateQueue,
                          requeue: this.handleReQueue,
                          cancel: this.handleCancelQueue,
                          delete: this.handleDeleteQueue,
                        }}
                      />
                    </TabPane>
                    <TabPane tab={i18n.f.fileOnServer} key="2">
                      <AllData
                        dataSource={this.state.queues}
                        loading={this.state.loading}
                        updateAt={this.state.updateAt}
                        onSearch={this.handleFilterFile}
                        action={{
                          requeue: this.handleReQueue,
                          cancel: this.handleCancelQueue,
                          delete: this.handleDeleteQueue,
                        }}
                        //new prop
                        vehicleId={this.state.vehicle_id_alldata}
                        plateNo={this.state.plate_no_alldata}
                      />
                    </TabPane>
                  </Tabs>
                )}
              </LangContext>
              <ModalVideoLog
                logVideoFile={this.state.log_video_file}
                isModalOpen={this.state.isModalVideoLogOpen}
                handleCancel={this.handleModalVideoLogClose}
                plateNo={this.state.plate_no_selected}
                camera_channel={this.state.camera_channel}
              />
            </Col>
          </Row>
        </GeneralStyledContent>
      </AuthorizeComponent>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

export default connect(mapStateToProps, {})(withResponsive(Index));
